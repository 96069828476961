import { NgZone, inject } from '@angular/core';
import { Actions, createEffect, ofType, provideEffects } from '@ngrx/effects';
import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HotToastService } from '@ngxpert/hot-toast';

export interface EmailState {
  error: string | null;
  otp: string | null;
}

const InitialState: EmailState = {
  error: null,
  otp: null,
};

export const VERIFYEMAIL_ACTIONS = createActionGroup({
  source: 'Verify Email API',
  events: {
    'Get Otp Request': emptyProps(),
    'Get Otp Success': emptyProps(),
    'Verify Email Success': emptyProps(),
    'Verify Email Request': props<{
      otp: string;
    }>(),
    'Verify Email Failure': props<{
      error: string;
    }>(),
  },
});

const verifyReducer = createReducer(
  InitialState,
  on(VERIFYEMAIL_ACTIONS.verifyEmailRequest, (state, action) => {
    return { ...state, otp: action.otp };
  }),
  on(VERIFYEMAIL_ACTIONS.verifyEmailFailure, (state, action) => {
    return { ...state, error: action.error };
  })
);

export const verifyFeature = createFeature({
  name: 'Verify-Email',
  reducer: verifyReducer,
});

export const { selectError, selectOtp } = verifyFeature;

const getOtp = createEffect(
  (action$ = inject(Actions)) => {
    const auth = inject(AuthService);
    const toast = inject(HotToastService);
    const router = inject(Router);
    return action$.pipe(
      ofType(VERIFYEMAIL_ACTIONS.getOtpRequest),
      switchMap(() =>
        auth.getOtp().pipe(
          map(() => VERIFYEMAIL_ACTIONS.getOtpSuccess()),
          tap(() => {
            toast.show('Otp sent to your Email address');
          })
        )
      )
    );
  },
  {
    functional: true,
  }
);
const verify = createEffect(
  (action$ = inject(Actions)) => {
    const auth = inject(AuthService);
    return action$.pipe(
      ofType(VERIFYEMAIL_ACTIONS.verifyEmailRequest),
      switchMap((action) =>
        auth
          .verifyEmail(action.otp)
          .pipe(map(() => VERIFYEMAIL_ACTIONS.verifyEmailSuccess()))
      )
    );
  },
  {
    functional: true,
  }
);
const verifyEmailSuccess = createEffect(
  (action$ = inject(Actions)) => {
    const auth = inject(AuthService);
    const router = inject(Router);
    return action$.pipe(
      ofType(VERIFYEMAIL_ACTIONS.verifyEmailSuccess),
      switchMap(() =>
        auth.refreshAccessToken().pipe(
          tap(() => {
            router.navigate(['/home/categories'], { replaceUrl: true });
          })
        )
      )
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const verifyEffects = provideEffects({
  verify,
  getOtp,
  verifyEmailSuccess,
});
