<div class="rounded-[15px]">
  <div class="flex p-2 place-items-center gap-3 justify-between">

    <div class="flex flex-col gap-1">
      <span class="font-semibold text-[17px]">{{ title }}</span>
      <span *ngIf="message" class="text-[13px] text-color-[#C8C5C5]">{{
        message
        }}</span>
    </div>
  </div>
  <div *ngIf="options.progressBar" class="bg-white">
    <div class="toast-progress !bg-white" [style.width]="width + '%'"></div>
  </div>
</div>