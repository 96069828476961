import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, provideEffects } from '@ngrx/effects';
import {
  createActionGroup,
  createFeature,
  createReducer,
  on,
  props,
} from '@ngrx/store';
import { map, of, switchMap } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { User } from 'src/app/signup/store/signup.store';
import { APP_ACTIONS } from 'src/app/store/app.store';

export interface JsonResponse {
  status: number;
  message: string;
  body: any;
}
export interface AuthState {
  access_token: string | null;
  user: User | null;
  loading: boolean;
  error: string | null;
}

type LoginType = 'NORMAL' | 'IDP';
type SubjectIssuer = 'google' | 'apple';

export type LoginCreds<T extends LoginType> = T extends 'NORMAL'
  ? { email: string; password: string }
  : { access_token: string; subject_issuer: SubjectIssuer };

const initialState: AuthState = {
  access_token: null,
  user: null,
  loading: false,
  error: null,
};

export const AUTH_ACTIONS = createActionGroup({
  source: 'Auth API',
  events: {
    'Login Request': props<{ creds: LoginCreds<LoginType> }>(),
    'Login Success': props<{ token: string }>(),
    'Login Failure': props<{ error: string }>(),
    'Logout Success': props<{ message: string }>(),
    'Logout Failure': props<{ error: string }>(),
  },
});

const authReducer = createReducer(
  initialState,
  on(AUTH_ACTIONS.loginRequest, (state) => {
    return { ...state, loading: true };
  }),
  on(AUTH_ACTIONS.loginSuccess, (state, action) => {
    return {
      ...state,
      access_token: action.token,
      loading: false,
    };
  }),
  on(AUTH_ACTIONS.loginFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
);

export const authFeature = createFeature({
  name: 'auth',
  reducer: authReducer,
});

export const { selectLoading, selectError, selectAccess_token } = authFeature;
// Type guard to check if the credentials are for 'NORMAL' login
function isNormalCreds(
  creds: LoginCreds<LoginType>
): creds is LoginCreds<'NORMAL'> {
  return (
    (creds as any).email !== undefined && (creds as any).password !== undefined
  );
}

// Type guard to check if the credentials are for 'IDP' login
function isIdpCreds(creds: LoginCreds<LoginType>): creds is LoginCreds<'IDP'> {
  return (
    (creds as any).access_token !== undefined &&
    (creds as any).subject_issuer !== undefined
  );
}
const loginEffect = createEffect(
  (actions$ = inject(Actions)) => {
    const authService = inject(AuthService);
    return actions$.pipe(
      ofType(AUTH_ACTIONS.loginRequest),
      switchMap(({ creds }) => {
        if (isNormalCreds(creds)) {
          return authService
            .login(creds.email, creds.password)
            .pipe(
              map((res) =>
                AUTH_ACTIONS.loginSuccess({ token: res['access_token'] })
              )
            );
        } else {
          return authService
            .loginWithIDP(creds.access_token, creds.subject_issuer)
            .pipe(
              map((res) =>
                AUTH_ACTIONS.loginSuccess({ token: res['access_token'] })
              )
            );
        }
      })
    );
  },
  { functional: true }
);

const loginSuccessEffect = createEffect(
  (action$ = inject(Actions)) => {
    const router = inject(Router);
    return action$.pipe(
      ofType(AUTH_ACTIONS.loginSuccess),
      switchMap((res) => {
        return of(
          APP_ACTIONS.setAuthenticated({
            authenticated: true,
            token: res.token,
          })
        );
      })
    );
  },
  { functional: true }
);

export const LoginEffects = provideEffects(
  { loginEffect },
  { loginSuccessEffect }
);
