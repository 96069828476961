import { Injectable, inject, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSound } from './store/app.store';

const audioList = [
  'fiveInARow',
  'click',
  'LevelPassed',
  'NewLevel',
  'Notification',
  'OptionSelected',
  'Stagecompleted',
  'Tap',
  'Wrongbuzzer',
] as const;

export type AudioType = (typeof audioList)[number];

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private readonly audioPool: HTMLAudioElement[] = [];
  store = inject(Store);
  sound = this.store.selectSignal(selectSound);
  constructor() {
    // Preload audio for each sound type
    audioList.forEach((type) => {
      const audio = new Audio();
      audio.preload = 'none';
      audio.src = `../assets/audio/${type}.mp3`;
      this.audioPool.push(audio);
    });
  }
  isSoundActive = signal(localStorage.getItem('sound') != 'off');
  play(soundType: AudioType) {
    if (!this.sound()) return;
    // Retrieve an audio element from the pool
    const audio = this.audioPool.find((a) => a.src.includes(soundType));
    // Reset the audio element to play it again
    if (audio) {
      audio.currentTime = 0;
      audio.play();
    }
  }
}
