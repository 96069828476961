import { inject } from '@angular/core';
import {
  Actions,
  act,
  createEffect,
  ofType,
  provideEffects,
} from '@ngrx/effects';
import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { EMPTY, catchError, map, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { User } from 'src/app/signup/store/signup.store';

export interface ChangePasswordState {
  error: string | null;
  loading: boolean;
  passwordSuccess: boolean;
}

export interface ChangePass {
  email: string;
  otp: string;
  password: string;
}

const initialState: ChangePasswordState = {
  error: null,
  loading: false,
  passwordSuccess: false,
};

export const CHANGEPASSWORD_ACTIONS = createActionGroup({
  source: 'Change Password API',
  events: {
    'Change Password Success': emptyProps(),
    'Change Password Failure': props<{ error: string }>(),
    'Change Password Request': props<{
      password: ChangePass;
    }>(),
  },
});

const passwordReducer = createReducer(
  initialState,
  on(CHANGEPASSWORD_ACTIONS.changePasswordSuccess, (state, action) => {
    return { ...state, loading: false, passwordSuccess: true };
  }),
  on(CHANGEPASSWORD_ACTIONS.changePasswordFailure, (state, action) => {
    return { ...state, error: action.error, loading: false };
  }),
  on(CHANGEPASSWORD_ACTIONS.changePasswordRequest, (state, action) => {
    return { ...state, loading: true };
  })
);

export const passwordFeature = createFeature({
  name: 'Change Password',
  reducer: passwordReducer,
});
export const { selectError, selectLoading, selectPasswordSuccess } =
  passwordFeature;

const passwordEffects = createEffect(
  (action$ = inject(Actions)) => {
    const auth = inject(AuthService);
    return action$.pipe(
      ofType(CHANGEPASSWORD_ACTIONS.changePasswordRequest),
      switchMap((action) => {
        return auth
          .resetPassword(
            action.password.otp,
            action.password.password,
            action.password.email
          )
          .pipe(
            map((page) => CHANGEPASSWORD_ACTIONS.changePasswordSuccess()),
            tap((res) => {
              return console.log('effect result', res);
            }),
            catchError(() => EMPTY)
          );
      })
    );
  },
  {
    functional: true,
  }
);
export const changePassEffects = provideEffects({
  passwordEffects,
});
