import { CanActivateFn, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './auth.guard';
import { Store, provideState } from '@ngrx/store';
import { signUpEffects, signupFeature } from './signup/store/signup.store';
import {
  verifyEffects,
  verifyFeature,
} from './verify-email/verify-email/verify-email.store';
import { inject } from '@angular/core';
import { selectAuthenticated, selectLoggedInUser } from './store/app.store';
import { map } from 'rxjs';
import {
  forgotEffects,
  forgotFeature,
} from './forgot-password/forgot-password.store';
import {
  changePassEffects,
  passwordFeature,
} from './change-password/store/change-password.store';
import { LoginEffects, authFeature } from './login/store/login.store';

export function canActivate(): CanActivateFn {
  return () => {
    return inject(Store)
      .select(selectAuthenticated)
      .pipe(map((auth) => !auth));
  };
}
export function canVisitVerifyEmail(): CanActivateFn {
  return () => {
    return inject(Store)
      .select(selectLoggedInUser)
      .pipe(map((user) => !user?.email_verified));
  };
}

export const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.route').then((h) => h.routes),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },

  {
    path: 'verify-email',
    loadComponent: () =>
      import('./verify-email/verify-email.component').then(
        (v) => v.VerifyEmailComponent
      ),
    providers: [provideState(verifyFeature), verifyEffects],
    canActivate: [canVisitVerifyEmail()],
  },
  {
    path: '',
    canActivateChild: [canActivate()],
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      // {
      //   path: 'splash',
      //   component: AppSwiperFeatureComponent,
      // },

      {
        path: 'login',
        loadComponent: () =>
          import('./login/login.component').then((l) => l.LoginComponent),
        data: {
          animation: 0,
        },
        providers: [provideState(authFeature), LoginEffects],
      },
      {
        path: 'signup',
        loadComponent: () =>
          import('./signup/signup.component').then((s) => s.SignupComponent),
        providers: [provideState(signupFeature), signUpEffects],
      },

      {
        path: 'forgot-password',
        loadComponent: () =>
          import('./forgot-password/forgot-password.component').then(
            (f) => f.ForgotPasswordComponent
          ),
        providers: [
          provideState(forgotFeature),
          provideState(passwordFeature),
          forgotEffects,
          changePassEffects,
        ],
      },

      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];
