import {
  AdLoadInfo,
  AdMob,
  AdMobRewardItem,
  AdOptions,
  AdmobConsentStatus,
  InterstitialAdPluginEvents,
  RewardAdOptions,
  RewardAdPluginEvents,
} from '@capacitor-community/admob';
import {
  BannerAdOptions,
  BannerAdPluginEvents,
  AdMobBannerSize,
} from '@capacitor-community/admob';

export let IS_INTERSTITIAL_AD_SHOWING = false;

// Listen for ad display events
AdMob.addListener(InterstitialAdPluginEvents.Showed, () => {
  IS_INTERSTITIAL_AD_SHOWING = true;
});

AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
  IS_INTERSTITIAL_AD_SHOWING = false;
});
export async function initializeAd(): Promise<void> {
  await AdMob.initialize();

  const [trackingInfo, consentInfo] = await Promise.all([
    AdMob.trackingAuthorizationStatus(),
    AdMob.requestConsentInfo(),
  ]);

  if (trackingInfo.status === 'notDetermined') {
    /**
     * If you want to explain TrackingAuthorization before showing the iOS dialog,
     * you can show the modal here.
     * ex)
     * const modal = await this.modalCtrl.create({
     *   component: RequestTrackingPage,
     * });
     * await modal.present();
     * await modal.onDidDismiss();  // Wait for close modal
     **/

    await AdMob.requestTrackingAuthorization();
  }

  const authorizationStatus = await AdMob.trackingAuthorizationStatus();
  if (
    authorizationStatus.status === 'authorized' &&
    consentInfo.isConsentFormAvailable &&
    consentInfo.status === AdmobConsentStatus.REQUIRED
  ) {
    await AdMob.showConsentForm();
  }
}

export async function resumeBannerAd() {
  await AdMob.resumeBanner();
}
export async function showBannerAd(options: BannerAdOptions): Promise<void> {
  AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
    // Subscribe Banner Event Listener
  });
  AdMob.resumeBanner();

  AdMob.addListener(
    BannerAdPluginEvents.SizeChanged,
    (size: AdMobBannerSize) => {
      // Subscribe Change Banner Size
    }
  );

  AdMob.showBanner(options);
}

export async function hideBanner() {
  await AdMob.hideBanner();
}

export async function showInterstitialAd(adOptions: AdOptions): Promise<void> {
  AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
    // Subscribe prepared interstitial
  });

  await AdMob.prepareInterstitial(adOptions);
  await AdMob.showInterstitial();
}

export async function showRewardVideoAd(
  options: RewardAdOptions
): Promise<void> {
  AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
    // Subscribe prepared rewardVideo
  });

  AdMob.addListener(
    RewardAdPluginEvents.Rewarded,
    (rewardItem: AdMobRewardItem) => {
      // Subscribe user rewarded
      console.log(rewardItem);
    }
  );

  await AdMob.prepareRewardVideoAd(options);
  const rewardItem = await AdMob.showRewardVideoAd();
}
