import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, provideEffects } from '@ngrx/effects';
import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { map, of, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { AUTH_ACTIONS } from 'src/app/login/store/login.store';
import { APP_ACTIONS } from 'src/app/store/app.store';

export interface SignupInterface {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

export interface User {
  id?: string;
  sub?: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  name?: string;
  phone?: number;
  branch?: string;
  faculty?: string;
  email_verified?: boolean;
  coins?: number;
}

export interface SignUpState {
  loading: boolean;
  error: string | null;
  user: User | null;
}

const initialState: SignUpState = {
  loading: false,
  error: null,
  user: null,
};

export const SIGNUP_ACTIONS = createActionGroup({
  source: 'SIGNUP API',
  events: {
    'Signup Request': props<{ user: User }>(),
    'Signup Success': emptyProps(),
    'Signup Failure': props<{ error: string }>(),
  },
});

const signupReducer = createReducer(
  initialState,
  on(SIGNUP_ACTIONS.signupRequest, (state, action) => {
    return { ...state, loading: true };
  }),
  // on(SIGNUP_ACTIONS.signupSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     user: action.user,
  //   };
  // }),
  on(SIGNUP_ACTIONS.signupFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);

export const signupFeature = createFeature({
  name: 'signup',
  reducer: signupReducer,
});

export const { selectLoading, selectUser, selectError } = signupFeature;

const signupEffect = createEffect(
  (action$ = inject(Actions)) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    return action$.pipe(
      ofType(SIGNUP_ACTIONS.signupRequest),
      switchMap((action) =>
        authService.signup(action.user).pipe(
          map(() => SIGNUP_ACTIONS.signupSuccess()),
          tap(() => router.navigate(['/verify-email']))
        )
      )
    );
  },
  {
    functional: true,
  }
);
const signupSuccessEffect = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(SIGNUP_ACTIONS.signupSuccess),
      switchMap((action) =>
        of(
          APP_ACTIONS.setAuthenticated({
            authenticated: true,
          })
        )
      )
    );
  },
  {
    functional: true,
  }
);
export const signUpEffects = provideEffects(
  { signupEffect },
  { signupSuccessEffect }
);
