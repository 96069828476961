import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  computed,
  effect,
  inject,
} from '@angular/core';
import { IonicModule, isPlatform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { Store } from '@ngrx/store';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { AuthService } from './auth.service';
import { App, App as CapacitorApp } from '@capacitor/app';
import { IS_INTERSTITIAL_AD_SHOWING, initializeAd } from './admob-config';
import {
  APP_ACTIONS,
  selectHeaderBg,
  selectLoading,
  selectLoggedInUser,
  selectOnline,
} from './store/app.store';
import { isTokenExpired } from './utils/Utils';
import { AudioService } from './audio.service';
import { AsyncPipe, Location, NgClass, NgIf, NgStyle } from '@angular/common';
import { Network } from '@capacitor/network';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AD_OPTIONS } from 'src/main';

import { NoInternetComponent } from './no-internet/no-internet.component';
import { filter, map, shareReplay, tap } from 'rxjs';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { performImmediateUpdate } from './app-update-utils';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { environment } from 'src/environments/environment';
import { toSignal } from '@angular/core/rxjs-interop';
import { HotToastService } from '@ngxpert/hot-toast';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';

register();
export type HeaderBg = 'bg-white' | 'bg-quiz' | 'bg-primary';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CustomToastComponent,
    NgIf,
    MatProgressBar,
    NgStyle,
    NgClass,
    NoInternetComponent,
    AsyncPipe,
    RouterOutlet,
    RouterLink,
  ],
  providers: [AudioService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  readonly store = inject(Store);
  readonly router = inject(Router);
  readonly cdr = inject(ChangeDetectorRef);
  readonly toastService = inject(HotToastService);
  readonly isOnline = this.store.selectSignal(selectOnline);
  readonly loading = this.store.selectSignal(selectLoading);
  readonly headerBg = this.store.selectSignal(selectHeaderBg);
  readonly authService = inject(AuthService);
  readonly loggedInUser$ = this.store.selectSignal(selectLoggedInUser);
  readonly adOptions = inject(AD_OPTIONS);
  readonly location = inject(Location);
  private readonly routerEvents$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map(({ url }) => url),
    shareReplay()
  );
  readonly activeRoute = toSignal(this.routerEvents$);
  readonly showNavBar = computed(() => {
    const url = this.activeRoute() as string;
    if (!url) return false;
    return (
      url.startsWith('/home/categories') ||
      url.startsWith('/home/profile') ||
      url.startsWith('/home/achievements')
    );
  });
  async isUpdateAvailable() {
    const updateAvailable = await AppUpdate.getAppUpdateInfo();
    return (
      updateAvailable.updateAvailability ===
      AppUpdateAvailability.UPDATE_AVAILABLE
    );
  }
  bundle!: BundleInfo;
  constructor() {
    if (Capacitor.getPlatform() !== 'web') {
      App.addListener('appStateChange', async (state) => {
        const version = await fetch(environment.liveUpdateManifestUrl);
        if (!version.ok) {
          console.error('Error while getting update manifest ');
          return;
        }
        type Manifest = {
          latestVersion: string;
          whatsNew: string[];
          updateUrl: string;
        };
        const manifest = (await version.json()) as Manifest;
        const currentLatestVersion = manifest.latestVersion;
        const currentAppVersion = localStorage.getItem('app_version') || '';
        console.log(
          'Server latest version ' +
            currentLatestVersion +
            ' app version ' +
            currentAppVersion
        );
        if (currentAppVersion !== currentLatestVersion) {
          if (state.isActive) {
            this.bundle = await CapacitorUpdater.download({
              url: manifest.updateUrl,
              version: currentLatestVersion,
            });
          }
          if (!state.isActive && this.bundle.version) {
            try {
              //this will reload new version in next launch
              await CapacitorUpdater.next(this.bundle);
              await CapacitorUpdater.notifyAppReady();
              localStorage.setItem('app_version', currentLatestVersion);
              alert("What's changed \n " + manifest.whatsNew.join('\n'));
            } catch (e) {
              console.error('error while updating ', e);
            }
          }
        }
      });
    }
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: '#232C41' });
    }

    effect(() => {
      const loggedInUser = this.loggedInUser$();
      if (loggedInUser) {
        if (!loggedInUser.email_verified) {
          this.router.navigate(['/verify-email']);
        } else if (!loggedInUser.branch) {
          this.router.navigate(['/home/branches']);
        }
      }
    });
  }
  isAndroid(): boolean {
    return isPlatform('android');
  }
  ngOnInit() {
    this.routerEvents$
      .pipe(
        tap((url) => {
          let bg: HeaderBg = 'bg-primary';
          if (
            url.startsWith('/home/quiz') ||
            url.startsWith('/home/mock-up') ||
            url.startsWith('/home/question-sets') ||
            url.startsWith('/home/battle-quiz')
          ) {
            bg = 'bg-quiz';
          } else if (
            url.startsWith('/home/aboutUs') ||
            url.startsWith('/home/help-support')
          ) {
            bg = 'bg-white';
          } else {
            bg = 'bg-primary';
          }

          this.store.dispatch(APP_ACTIONS.changeHeaderBg({ headerBg: bg }));
        })
      )
      .subscribe();
    Network.addListener(
      'networkStatusChange',
      ({ connected, connectionType }) => {
        if (environment.appUrl.startsWith('http://localhost')) return;
        console.log('Network status changed', connected, connectionType);
        this.store.dispatch(APP_ACTIONS.setOnline({ online: connected }));
        this.cdr.detectChanges();
      }
    );

    this.store.dispatch(
      APP_ACTIONS.setAuthenticated({
        authenticated: !isTokenExpired(localStorage.getItem('access_token')),
      })
    );

    CapacitorApp.addListener('backButton', (e: { canGoBack: any }) => {
      const url = this.router.url;
      console.log('url ', url, e.canGoBack);
      if (
        IS_INTERSTITIAL_AD_SHOWING ||
        url.startsWith('/home/mock-up') ||
        url.startsWith('/home/quiz/questions')
      ) {
        console.info('not allowed to go back from this page');
        return;
      }
      const canGoBack =
        e.canGoBack && url !== '/home/categories' && url != '/login';
      if (!canGoBack) {
        if (confirm('Are you sure you want to exit the app ?')) {
          CapacitorApp.exitApp();
        }
      } else {
        this.location.back();
      }
    });
    initializeAd();
    /**
    showBannerAd({
      ...this.adOptions,
      adId: this.adOptions.bannerAdId,
      position: BannerAdPosition.BOTTOM_CENTER,
    });
    */
  }

  async updateApp() {
    performImmediateUpdate();
  }

  checkForUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();

    if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
      // An update is available
      if (result.immediateUpdateAllowed) {
        // Show a dialog to prompt the user to update
        const shouldUpdate = await this.showUpdateDialog();

        if (shouldUpdate) {
          // Perform an immediate update
          await AppUpdate.performImmediateUpdate();
        }
      } else if (result.flexibleUpdateAllowed) {
        // Start a flexible update
        await AppUpdate.startFlexibleUpdate();
      }
    }
  };

  showUpdateDialog = async () => {
    // Implement your own dialog UI here
    // Return true if the user wants to update, false otherwise
    // For example, using the built-in confirm dialog:
    return confirm(
      'A new version of the app is available. Do you want to update now?'
    );
  };
  reloadApp(): void {
    window.location.reload();
  }
}
