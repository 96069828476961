/**
 *  For more information:
 *  https://developers.google.com/admob/ios/banner#banner_sizes
 *  https://developers.google.com/android/reference/com/google/android/gms/ads/AdSize
 *
 * */
export var BannerAdSize;
(function (BannerAdSize) {
  /**
   * Mobile Marketing Association (MMA)
   * banner ad size (320x50 density-independent pixels).
   */
  BannerAdSize["BANNER"] = "BANNER";
  /**
   * Interactive Advertising Bureau (IAB)
   * full banner ad size (468x60 density-independent pixels).
   */
  BannerAdSize["FULL_BANNER"] = "FULL_BANNER";
  /**
   * Large banner ad size (320x100 density-independent pixels).
   */
  BannerAdSize["LARGE_BANNER"] = "LARGE_BANNER";
  /**
   * Interactive Advertising Bureau (IAB)
   * medium rectangle ad size (300x250 density-independent pixels).
   */
  BannerAdSize["MEDIUM_RECTANGLE"] = "MEDIUM_RECTANGLE";
  /**
   * Interactive Advertising Bureau (IAB)
   * leaderboard ad size (728x90 density-independent pixels).
   */
  BannerAdSize["LEADERBOARD"] = "LEADERBOARD";
  /**
   * A dynamically sized banner that is full-width and auto-height.
   */
  BannerAdSize["ADAPTIVE_BANNER"] = "ADAPTIVE_BANNER";
  /**
   * @deprecated
   * Will be removed in next AdMob versions use `ADAPTIVE_BANNER`
   * Screen width x 32|50|90
   */
  BannerAdSize["SMART_BANNER"] = "SMART_BANNER";
})(BannerAdSize || (BannerAdSize = {}));
