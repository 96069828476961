/**
 *  For more information:
 *  https://developers.google.com/admob/unity/reference/namespace/google-mobile-ads/ump/api#debuggeography
 *
 * */
export var AdmobConsentDebugGeography;
(function (AdmobConsentDebugGeography) {
  /**
   * Debug geography disabled.
   */
  AdmobConsentDebugGeography[AdmobConsentDebugGeography["DISABLED"] = 0] = "DISABLED";
  /**
   * Geography appears as in EEA for debug devices.
   */
  AdmobConsentDebugGeography[AdmobConsentDebugGeography["EEA"] = 1] = "EEA";
  /**
   * Geography appears as not in EEA for debug devices.
   */
  AdmobConsentDebugGeography[AdmobConsentDebugGeography["NOT_EEA"] = 2] = "NOT_EEA";
})(AdmobConsentDebugGeography || (AdmobConsentDebugGeography = {}));
