import { Injectable, inject, signal } from '@angular/core';
import { ViewTransitionInfo } from '@angular/router';

export function onViewTransitionCreated(info: ViewTransitionInfo) {
    const currentTransitionService = inject(CurrentTransitionService);
    currentTransitionService.currentTransition.set(info);
    // Update current transition when animation finishes
    info.transition.finished.finally(() => {
        currentTransitionService.currentTransition.set(null);
    });
}

@Injectable({
    providedIn: 'root',
})
export class CurrentTransitionService {
    readonly currentTransition = signal<ViewTransitionInfo | null>(null);
}
