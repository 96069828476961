import {
  InjectionToken,
  enableProdMode,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';

import { AppComponent } from './app/app.component';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { IonicRouteStrategy, IonicModule, isPlatform } from '@ionic/angular';
import {
  RouteReuseStrategy,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withViewTransitions,
} from '@angular/router';
import { routes } from './app/app.route';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideState, provideStore } from '@ngrx/store';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppHttpInterceptor } from './app/http.interceptor';
import { AppEffects, appFeature } from './app/store/app.store';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { provideToastr } from 'ngx-toastr';
import { CustomToastComponent } from './app/custom-toast/custom-toast.component';
import { environment } from './environments/environment';
import { provideServiceWorker } from '@angular/service-worker';
import {
  verifyEffects,
  verifyFeature,
} from './app/verify-email/verify-email/verify-email.store';
import { onViewTransitionCreated } from './app/transition.service';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { MarkdownModule } from 'ngx-markdown';

if (environment.production) {
  enableProdMode();
}

export type CommonAdOptions = {
  isTesting: boolean;
  bannerAdId: string;
  interstitialAdId: string;
};
export const AD_OPTIONS = new InjectionToken<CommonAdOptions>('adOptions');
const adOptions: CommonAdOptions = {
  isTesting: !environment.production,
  bannerAdId: isPlatform('ios')
    ? environment.iosBannerAdId
    : environment.androidBannerAdId,
  interstitialAdId: isPlatform('ios')
    ? environment.iosInterstitialAdId
    : environment.androidInterstitalAdId,
};
bootstrapApplication(AppComponent, {
  providers: [
    provideToastr({
      toastComponent: CustomToastComponent,
      progressBar: true,
    }),
    provideHotToastConfig({
      style: {
        marginTop: '56px',
      },
    }),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withViewTransitions({ onViewTransitionCreated }),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      })
    ),
    provideStore(),
    AppEffects,
    provideStoreDevtools({
      trace: true,
    }),
    provideState(appFeature),
    provideState(verifyFeature),
    verifyEffects,
    { provide: AD_OPTIONS, useValue: adOptions },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    importProvidersFrom(
      BrowserModule,
      IonicModule.forRoot(),
      MarkdownModule.forRoot()
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideAnimationsAsync(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
}).catch((err) => console.log(err));
