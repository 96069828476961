import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {
  EMPTY,
  Observable,
  catchError,
  finalize,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { APP_ACTIONS } from './store/app.store';
import { isTokenExpired } from './utils/Utils';
import { HotToastService } from '@ngxpert/hot-toast';
import { ThreadIdService } from './threadId.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private readonly authService = inject(AuthService);
  private readonly store = inject(Store);
  private readonly toast = inject(HotToastService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    //dispatch loading event
    this.setLoading(true);

    const accessToken = this.authService.accessToken;
    let request$ = next.handle(this.setTokenInRequest(request));
    //also check refresh token to avoid recursion
    if (
      !request.url.endsWith('login') &&
      !request.url.endsWith('refresh-token') &&
      accessToken &&
      isTokenExpired(accessToken)
    ) {
      request$ = this.authService.refreshAccessToken().pipe(
        catchError((error: HttpErrorResponse) => {
          //if error in refresh token then
          this.setAppError(401, 'Session has expired');
          return EMPTY;
        }),
        switchMap(() => {
          return next.handle(this.setTokenInRequest(request));
        })
      );
    }
    return request$.pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
       
          if (event.status === 201) {
            this.toast.success(event.body.message, {
              dismissible: true,
              autoClose: true,
              duration: 3000, // Duration in milliseconds
            });
          }
          return (event = event.clone({
            body: event.body.body,
            headers: event.headers,
          }));
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(this.handleError(error));
      }),
      finalize(() => this.setLoading(false))
    );
  }

  private setAppError(status: number, message: string) {
    this.store.dispatch(
      APP_ACTIONS.setAppError({
        error: {
          status: status,
          message: message,
        },
      })
    );
  }

  private setTokenInRequest(request: HttpRequest<unknown>) {
    const skipIntercept = request.headers.has('skip');

    let customized = request.headers;
    if (!skipIntercept && this.authService.accessToken) {
      customized = customized.append(
        'AUthorization',
        `Bearer ${this.authService.accessToken}`
      );
    } else {
      customized.delete('skip');
    }

    request = request.clone({
      headers: customized,
      body: request.body,
    });
    return request;
  }

  private setLoading(loading: boolean) {
    this.store.dispatch(
      APP_ACTIONS.setLoading({
        loading: loading,
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred';

    console.error('error ', error.message);
    switch (error.status) {
      case 400:
        {
          const errors = error.error['Errors'] as string[];
          if (errors)
            errorMessage = errors.reduce((acc, curr) => acc + ' & ' + curr);
          else errorMessage = error.error['message'];
        }
        break;
      case 409: {
        errorMessage = error.error.message;
        break;
      }
      case 401: {
        if (window.location.toString().search('login') != -1)
          errorMessage = 'Login failed !! Incorrect username or password';
        else if (error.url?.search('refresh-token') != -1)
          errorMessage = 'Session has expired !! Please login again';
        else errorMessage = 'Bad Request.. Please check Again';
        break;
      }
      default:
        errorMessage = 'Something went wrong.. We will resolve it ASAP!!';
    }

    // You can also dispatch a general error action here
    // to update your state with the error information.

    this.setAppError(error.status, errorMessage);
    return errorMessage;
  }
}
