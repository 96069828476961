import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, provideEffects } from '@ngrx/effects';
import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { User } from 'src/app/signup/store/signup.store';

export interface ForgotPassState {
  error: string | null;
  email: string | null;
  loading: boolean;
  user: User | null;
  success: boolean;
}

const InitialState: ForgotPassState = {
  error: null,
  email: null,
  loading: false,
  user: null,
  success: false,
};

export const FORGOTPASS_ACTIONS = createActionGroup({
  source: 'Forgot Password API',
  events: {
    'Forgot Password Request': props<{
      email: string;
    }>(),
    'Forgot Password Success': emptyProps(),
    'Forgot Password Failure': props<{
      error: string;
    }>(),
  },
});

const forgotReducer = createReducer(
  InitialState,
  on(FORGOTPASS_ACTIONS.forgotPasswordSuccess, (state, action) => {
    return { ...state, loading: true, success: true };
  }),
  on(FORGOTPASS_ACTIONS.forgotPasswordFailure, (state, action) => {
    return { ...state, error: action.error };
  }),
  on(FORGOTPASS_ACTIONS.forgotPasswordRequest, (state, action) => {
    return { ...state, loading: true };
  })
);

export const forgotFeature = createFeature({
  name: 'Forgot Password',
  reducer: forgotReducer,
});

export const { selectError, selectEmail, selectSuccess } = forgotFeature;

const forgot = createEffect(
  (action$ = inject(Actions)) => {
    const authService = inject(AuthService);
    return action$.pipe(
      ofType(FORGOTPASS_ACTIONS.forgotPasswordRequest),
      switchMap((action) =>
        authService
          .forgotPassword(action.email)
          .pipe(map(() => FORGOTPASS_ACTIONS.forgotPasswordSuccess()))
      )
    );
  },
  {
    functional: true,
  }
);

export const forgotEffects = provideEffects({
  forgot,
});
