/**
 * @see https://developer.android.com/reference/android/widget/LinearLayout#attr_android:gravity
 */
export var BannerAdPosition;
(function (BannerAdPosition) {
  /**
   * Banner position be top-center
   */
  BannerAdPosition["TOP_CENTER"] = "TOP_CENTER";
  /**
   * Banner position be center
   */
  BannerAdPosition["CENTER"] = "CENTER";
  /**
   * Banner position be bottom-center(default)
   */
  BannerAdPosition["BOTTOM_CENTER"] = "BOTTOM_CENTER";
})(BannerAdPosition || (BannerAdPosition = {}));
