export var MaxAdContentRating;
(function (MaxAdContentRating) {
  /**
   * Content suitable for general audiences, including families.
   */
  MaxAdContentRating["General"] = "General";
  /**
   * Content suitable for most audiences with parental guidance.
   */
  MaxAdContentRating["ParentalGuidance"] = "ParentalGuidance";
  /**
   * Content suitable for teen and older audiences.
   */
  MaxAdContentRating["Teen"] = "Teen";
  /**
   * Content suitable only for mature audiences.
   */
  MaxAdContentRating["MatureAudience"] = "MatureAudience";
})(MaxAdContentRating || (MaxAdContentRating = {}));
